import { Col, DatePicker, Input, Row } from "antd";
import { GlobalContext } from "lib/contexts/GlobalContext";
import moment from "moment";
import { useContext } from "react";
import { useLocation } from "react-router-dom";

export default function SelectDate({ setDateTime, dateTime, selectedFilters }) {
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);
  const location = useLocation();

  const pathName = location.pathname;
  const isVolSurfacePage = pathName.includes("vol-surface");
  const hideDateFilterOnProdForVolSurface =
    process.env.REACT_APP_NODE_ENV === "production" && isVolSurfacePage;

  const handleRangePickerChange = (dateStr) => {
    setDateTime(dateStr);
  };

  const defaultDateTime = moment().subtract(1, "days").set({
    hour: 18,
    minute: 0,
    second: 0,
  })

  return (
    <>
      <Col span={24}>
        {!hideDateFilterOnProdForVolSurface && (
          <Row
            size={"middle"}
            className={`${dark_Theme && "date-picker-dark"}`}
          >
            <Col span={3}>
              <span style={{ whiteSpace: "nowrap" }}>
                Date/Time of Surface:
              </span>
            </Col>
            <Col>
              <DatePicker
                onChange={handleRangePickerChange}
                className={dateTime && "filled--data_rangePicker__background"}
                popupClassName={dark_Theme && "date-picker-dropdown-dark"}
                value={
                  dateTime
                    ? moment(dateTime, "YYYY-MM-DD HH:mm:ss")
                    : moment(defaultDateTime, "YYYY-MM-DD HH:mm:ss")
                }
                showTime
              />
            </Col>
            <Col offset={1} span={3}>
              <span style={{ whiteSpace: "nowrap" }}>
                Current EOD Surface Time:
              </span>
            </Col>
            <Col>
              <Input value={"18:00:00"} disabled />
            </Col>
          </Row>
        )}
      </Col>
    </>
  );
}
