import { AgGridReact } from "ag-grid-react";
import { getRowClass, getRowStyle } from "lib/constant/AgGridCommonStyle";
import { VOL_SURFACE_STICKY_STRIKE_COLS } from "lib/constant/VolSurface";
import { GlobalContext } from "lib/contexts/GlobalContext";
import { useContext, useMemo, useRef } from "react";

function SingleStrikeTable({rowData}) {
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);
  const gridRef = useRef();
  const columnDefs = useMemo(() => VOL_SURFACE_STICKY_STRIKE_COLS, []);

   const getRowId = (params) => {
     return params.data.id;
   };
    const gridOptions = {
      stopEditingWhenGridLosesFocus: false,
    };

    const onGridReady = (params) => {
      params.api.sizeColumnsToFit();
      // gridRef.current.api.showLoadingOverlay();
    };


  return (
    <AgGridReact
      getRowId={getRowId}
      // onCellEditingStopped={onCellEditingStopped}
      gridOptions={gridOptions}
      onCellFocused={(params) => {
        params.api.stopEditing();
        gridRef.current.api.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: params.column.colId,
        });
      }}
      onGridReady={onGridReady}
      headerHeight={30}
      defaultColDef={{ suppressMenu: true }}
      enterMovesDownAfterEdit={true}
      enterMovesDown={true}
      rowHeight={30}
      rowData={rowData}
      paginationPageSize={31}
      getRowStyle={(params) => {
        if (params.data.field !== "strike") {
          return {
            borderTop: "none",
            borderBottom: "none",
          };
        }
        getRowStyle(params);
      }}
      getRowClass={getRowClass}
      ref={gridRef}
      stopEditingWhenCellsLoseFocus={true}
      suppressMovableColumns={true}
      columnDefs={columnDefs}
      groupDefaultExpanded={1}
      suppressContextMenu
      className={`ag-theme-alpine pricer-ag-grid__table ${
        dark_Theme ? " ag-theme-balham-dark" : "ag-theme-balham"
      }`}
    />
  );
}

export default SingleStrikeTable;
