/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Button, Row } from "antd";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

import { SHORTCUT_FILTER } from "lib/constant/VolSurface";
import { storeInLocalStorage } from "lib/helpers/Pricer/Table";
import React, { useEffect } from "react";
import { Sticky_Delta_Strike_LABELS } from "lib/constant/GeneralActionButtons";

export default function Shortcuts({
  dark_Theme,
  currentTab,
  volSurfaceFilterShortcuts,
  selectedFilters,
  setSelectedFilters,
  handleShortcutChange,
}) {
  const location = useLocation();
  const pathName = location.pathname;
  const isSticky = currentTab === Sticky_Delta_Strike_LABELS.STICKY_Strike;

  const shortcuts = isSticky
    ? volSurfaceFilterShortcuts?.filter(
        (item) => item?.product_contract_style_id === 1
      )
    : volSurfaceFilterShortcuts;

  function onClickHandle(item) {
    setSelectedFilters((prev) => ({
      ...prev,
      product_id: item.product_contract_style_id,
      shortcutFilter: item.product_contract_style_id,
      comparison_surface_id: null,
      live_surface_id: null,
    }));
    storeInLocalStorage(
      SHORTCUT_FILTER,
      JSON.stringify(item.product_contract_style_id)
    );
    handleShortcutChange(item.product_contract_style_id);
  }

  useEffect(() => {
    if (pathName.includes("vol-surface-data")) {
      setSelectedFilters((prev) => ({
        ...prev,
        shortcutFilter:
          JSON.parse(localStorage.getItem(SHORTCUT_FILTER)) ||
          shortcuts?.at(0)?.product_contract_style_id,
        product_id: shortcuts?.at(0)?.product_contract_style_id.toString(),
      }));
    }
  }, [pathName, setSelectedFilters, shortcuts]);

  useEffect(() => {
    if (isSticky && !pathName.includes("vol-surface-data")) {
      setSelectedFilters((prev) => ({
        ...prev,
        shortcutFilter: 1,
        product_id: 1,
      }));
    }
  }, [isSticky, pathName]);

  return (
    <Col span={24}>
      <Row className="bias-btn">
        <Col span={3}>Shortcuts:</Col>
        <Col>
          <Row gutter={12}>
            {shortcuts?.map((item) => {
              return (
                <Col key={item.label}>
                  <Button
                    className={classNames({
                      "ant-btn-dark": dark_Theme,
                      "selected-button":
                        item?.product_contract_style_id ===
                        selectedFilters["shortcutFilter"],
                    })}
                    key={item.label}
                    onClick={() => onClickHandle(item)}
                  >
                    {item.label}
                  </Button>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </Col>
  );
}
