import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "lib/contexts/GlobalContext";

import "./gridStyles.css";
import { getVolSurfaceStickyStrikes } from "api/VolSurface/VolSurface";
import { useVolSurfaceControlTable } from "lib/contexts/VolSurfaceControlTableContext";
import SingleStrikeTable from "./SingleStrikeTable";

function StickyStrikeTable() {
  const [rowsData, setRowsData] = useState([]);
  const [gridHeight, setGridHeight] = useState("auto");

  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);
  const { selectedFilters } = useVolSurfaceControlTable();

  useEffect(() => {
    if (!selectedFilters?.product_id) return;
    getVolSurfaceStickyStrikes({
      product_contract_style_id: selectedFilters?.product_id,
    }).then((res) => {
      setRowsData(res.data.data);
    });
  }, [selectedFilters?.product_id]);

  //handle grid height
  useEffect(() => {
    if (rowsData?.length === 0) return;
    const numberOfRowData = rowsData[0]?.length;
    const calculatedHeight = numberOfRowData * 30 + 95;
    setGridHeight(`${calculatedHeight}px`);
  }, [rowsData]);

  if (rowsData.length === 0) return null;

  return (
    <div
      className={`${
        dark_Theme || false
          ? "pricer-ag-grid__container_dark"
          : "pricer-ag-grid__container"
      }`}
      style={{
        height: gridHeight, //"61rem",
        width: "50%",
      }}
    >
      {rowsData?.map((row, index) => {
        return <SingleStrikeTable key={index} rowData={row} />;
      })}
    </div>
  );
}

export default StickyStrikeTable;

