import { cellStyle } from "lib/helpers/AuditTrail/generalHelper";
import {
  formatNumber,
  formatNumericValue,
  formatToNDecimalPlaces,
} from "lib/helpers/GeneralFuncHelpers";
import { valueFormatterFunc, valueParserFunc } from "lib/helpers/Pricer/Table";
import {
  cellFormatter,
  cellStyleDataVolSurfaceForFirstCol,
  cellStyleFuncForVolSurfaceData,
  editableBasedOnMetricForTenorCol,
  getCellStyle,
  navigateToNextCell,
  valueFormatterForEstimateFirstCol,
  valueFormatterForEstimateSecondCol,
} from "lib/helpers/VulSurface/VulSurface";
import {
  cellStyleForFirstCol,
  cellStyleFunc,
  isCellEditable,
} from "lib/helpers/VulSurface/VulSurface";

export const VOL_SURFACE_BUTTON_TAB = "volSurface_buttonTab";
export const VOL_SURFACE_STICKY_DELTA_OR_STRIKE =
  "volSurface_stickyDeltaOrStrick";
export const VOL_SURFACE_GRAPGH_FILTERS = "volSurface_graphFilters";
export const RISK_FREE_KEY = "riskFree";
export const SHORTCUT_FILTER = "shortcutFilter";
export const SCROLL_POSITION = "scrollPosition";

const commonCellStyle = cellStyleFunc;
const commonValueFormatter = cellFormatter;
const commonMinWidth = 65;

/**
 * Generates an array of column objects based on the given array of strikes and the specified side.
 * @param {Array} strikes - The array of strikes to be used for generating the columns.
 * @param {string} side - The side for which the columns are being generated ("Calls" or "Puts").
 * @return {Array} An array of column objects based on the input strikes and side.
 */
const generateStrikeColumns = (strikes, side) => {
  return strikes.map((strike, index) => ({
    headerName: strike.toString(),
    field: String(side === "Calls" ? 6 + index : index),
    minWidth: commonMinWidth,
    menuTabs: [],
    valueParser: valueParserFunc,
    flex: 1,
    editable: isCellEditable,
    suppressSizeToFit: true,
    cellStyle: commonCellStyle,
    valueFormatter: commonValueFormatter,
  }));
};

const generateStrikeForVolSurfaceColumns = (strikes, side) => {
  return strikes.map((strike, index) => ({
    headerName: strike.toString(),
    field: String(side === "Calls" ? 6 + index : index),
    minWidth: commonMinWidth,
    menuTabs: [],
    valueParser: valueParserFunc,
    flex: 1,
    editable: isCellEditable,
    suppressSizeToFit: true,
    cellStyle: cellStyleFuncForVolSurfaceData,
    valueFormatter: commonValueFormatter,
  }));
};

const putStrikes = ["-0.01", "-0.05", "-0.1", "-0.2", "-0.3", "-0.4"];
const callStrikes = ["0.5", "0.4", "0.3", "0.2", "0.1", "0.05", "0.01"];

export const VOL_SURFACE_Control_COLS = [
  {
    headerName: "Tenor",
    field: "underlying",
    pinned: "left",
    minWidth: 100,
    menuTabs: [],
    headerClass: "month-estimates",
    editable: editableBasedOnMetricForTenorCol,
    valueParser: valueParserFunc,
    cellStyle: cellStyleForFirstCol,
    valueFormatter: (params) => {
      if (params.colDef.headerName === "Tenor") {
        if (params.data.metric === "volatility") {
          return formatNumber(params.data.newValue || params.data.underlying);
        } else if (params.data.metric === "change") {
          return params.data.tenor;
        }
        return "";
      }
    },
  },
  {
    headerName: "Puts",
    cellClass: "center",
    children: [
      {
        cellClass: "no-border",
        headerName: "Delta",
        field: "metric",
        minWidth: 70,
        cellStyle: commonCellStyle,
      },
      ...generateStrikeColumns(putStrikes, "Puts"),
    ],
  },
  {
    headerName: "Calls",
    cellClass: "no-border",
    children: generateStrikeColumns(callStrikes, "Calls"),
  },
];

const commonEstimatesMinWidth = 50;
export const VOL_SURFACE_Month_Estimates_COLS = [
  {
    headerName: "Month Estimates",
    minWidth: "100%",
    flex: 1,
    suppressSizeToFit: true,
    colSpan: () => 2,
    headerClass: "month-estimates",
    children: [
      {
        headerName: "Option 1",
        field: "0",
        menuTabs: [],
        suppressSizeToFit: true,
        minWidth: commonEstimatesMinWidth,
        flex: 1,
        valueParser: valueParserFunc,
        colSpan: (params) => (params.data.metric === "spread" ? 2 : 1),
        headerClass: "month-estimates",
        valueFormatter: valueFormatterForEstimateFirstCol,
        editable: (params) => params.data.metric === "strike",
        cellStyle: (params) => getCellStyle(params),
      },

      {
        headerName: "Option 2",
        field: "1",
        suppressSizeToFit: true,
        minWidth: commonEstimatesMinWidth,
        flex: 1,
        menuTabs: [],
        headerClass: "month-estimates",
        headerStyle: { paddingLeft: "2rem !important" },
        valueParser: valueParserFunc,
        valueFormatter: valueFormatterForEstimateSecondCol,
        editable: (params) => params.data.metric === "strike",
        cellStyle: (params) => getCellStyle(params),
      },
    ],
  },
];

export const VOL_SURFACE_Data_COLS = [
  {
    headerName: "Tenor",
    field: "tenor",
    pinned: "left",
    minWidth: 100,
    menuTabs: [],
    headerClass: "month-estimates",
    editable: editableBasedOnMetricForTenorCol,
    valueParser: valueParserFunc,
    cellStyle: cellStyleDataVolSurfaceForFirstCol,
  },
  {
    headerName: "Underlying",
    field: "underlying",
    minWidth: 100,
    menuTabs: [],
    headerClass: "month-estimates",
    editable: editableBasedOnMetricForTenorCol,
    valueParser: valueParserFunc,
    cellStyle: cellStyleFuncForVolSurfaceData,
  },
  {
    headerName: "Puts",
    cellClass: "center",
    children: [
      {
        cellClass: "no-border",
        headerName: "Delta",
        field: "metric",
        minWidth: 70,
        cellStyle: cellStyleFuncForVolSurfaceData,
      },
      ...generateStrikeForVolSurfaceColumns(putStrikes, "Puts"),
    ],
  },
  {
    headerName: "Calls",
    cellClass: "no-border",
    children: generateStrikeForVolSurfaceColumns(callStrikes, "Calls"),
  },
];

export const VOL_SURFACE_STICKY_STRIKE_COLS = [
  {
    headerName: "Tenor",
    field: "tenor",
    valueFormatter: (params) => {
      const totalRows = params.api.getDisplayedRowCount();
      const { tenor } = params.data;
      const rowIndex = params.node.rowIndex;
      return Math.round(totalRows / 2) - 2 === rowIndex ? tenor : "";
    },
    cellStyle: (params) => {
      return {
        textAlign: "center",
      };
    },
  },
  {
    headerName: "Strike",
    field: "strike",
    valueFormatter: valueFormatterFunc,
    valueParser: valueParserFunc,
  },
  {
    headerName: "C/P",
    field: "cp",
  },
  {
    headerName: "UL",
    field: "underlying",
    editable: true,
    valueFormatter: (params) => {
      const totalRows = params.api.getDisplayedRowCount();
      const { underlying } = params.data;
      const rowIndex = params.node.rowIndex;
      return Math.round(totalRows / 2) - 2 === rowIndex ? underlying : "";
    },
  },
  {
    headerName: "Vol",
    field: "vol",
    editable: true,
    valueFormatter: (params) => {
      const value = params.value;
      return !value ? "" : formatToNDecimalPlaces(2, value) + "%";
    },
  },
  {
    headerName: "Premium",
    field: "premium",
    valueFormatter: (params) => {
      const value = params.value;
      return !value ? "" : formatToNDecimalPlaces(3, value);
    },
  },
  {
    headerName: "Moneyness",
    field: "moneyness",
    valueFormatter: (params) => {
      const value = params.value;
      return !value ? "" : formatToNDecimalPlaces(2, value);
    },
  },
  {
    headerName: "Delta",
    field: "delta",
    valueFormatter: (params) => {
      const value = params.value;
      return !value ? "" : formatToNDecimalPlaces(2, value) + "%";
    },
  },
  {
    headerName: "Day",
    field: "day",
    headerClass: "custom-header-icon",
    headerValueGetter: function (params) {
      return params.colDef.headerName;
    },
  },
  {
    headerName: "Week",
    field: "week",
    headerClass: "custom-header-icon",
    headerValueGetter: function (params) {
      return params.colDef.headerName;
    },
  },
].map((col) => ({
  ...col,
  resizable: true,
  // valueFormatter: cellFormatter,
  cellStyle: (params) => {
    const col = params.colDef.field;
    return {
      textAlign: col === "tenor" || col === "underlying" ? "center" : "left",
      borderBottom:
        col === "tenor" || col === "underlying"
          ? "none"
          : "1px solid var(--border-color)",
      borderRight: "1px solid var(--border-color)",
      color:
        col === "delta"
          ? "#E1A2C7"
          : col === "strike"
          ? "#C9B9CB"
          : col === "vol"
          ? "#8CA9BB"
          : "",
    };
  },
}));
