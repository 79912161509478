import GraphsContainer from "../VolSurfaceGraph/GraphsContainer";

function StickyStrikeGraphs() {
  return (
    <div style={{ width: "50%" }}>
      <div className="stickyStrikeGraphsTitle text-center">Sticky Strike Graphs</div>
      <GraphsContainer
        volSurfaceData={[]}
        Highcharts={[]}
      />
    </div>
  );
}

export default StickyStrikeGraphs
