import React, { Suspense, lazy, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactGA from "react-ga4";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ROUTES, { RenderRoutes } from "./routes";
import PreLoadPagesSpin from "components/PreLoadPagesSpin";
import * as Sentry from "@sentry/react";
// Ag Grid Style
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "./styles.scss";

const GlobalState = lazy(() => import("lib/contexts/GlobalContext"));
const queryClient = new QueryClient({});

const App = () => {
  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === "production") {
      const originalError = console.error;
      console.error = (...args) => {
        if (
          args.some(
            (arg) =>
              typeof arg === "string" &&
              (arg.startsWith("*") || arg.startsWith("AG Grid:"))
          )
        ) {
          return;
        }
        originalError(...args);
      };
    }
  }, []);

  // Initialize Google Analytics
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);

  return (
    <Suspense fallback={<PreLoadPagesSpin />}>
      <QueryClientProvider client={queryClient}>
        <GlobalState>
          <main id="content-root">
            <RenderRoutes routes={ROUTES} />
          </main>
        </GlobalState>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Suspense>
  );
};

export default Sentry.withProfiler(App);
