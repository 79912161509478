import React, { useContext, useState } from "react";
import { Modal, Button, DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import advancedFormat from "dayjs/plugin/advancedFormat";

// API
import { triggerPushOptionToAuditTrailAPI } from "api/Pricer/Table";
// Helpers
import { toast } from "lib/helpers/utilities";
import { GlobalContext } from "lib/contexts/GlobalContext";
import { ACTION_BUTTONS_NAME } from "lib/constant/GeneralActionButtons";
import { FIELD_NAMES } from "lib/constant/Pricer/PricerTable";
import PushToAuditTrailItem from "./PushToAuditTrailItem";

const LABEL_NAME = {
  SIMILAR_OPTION: { label: "Shorthand" },
  DATE: { label: "Date" },
  TIME: { label: "Time" },
  CANCEL: { label: "Cancel" },
  CONFIRM: { label: "Confirm" },
  SHORTHAND: { label: "Shorthand" },
};

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const PushToAuditTrail = (props) => {
  const [date, setDate] = useState(dayjs());
  const [time, setTime] = useState(dayjs());
  const [isLoading, setIsLoading] = useState(false);
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);

  const onDateChange = (date, dateString) => {
    setDate(date);
  };

  const onTimeChange = (time, timeString) => {
    setTime(time);
  };
  const onPushToAuditTrailClick = async () => {
    if (!date) return toast("Please select date", "error");
    if (!time) return toast("Please select time", "error");
    setIsLoading(true);
    try {
      const res = await triggerPushOptionToAuditTrailAPI(
        props.isAggData[FIELD_NAMES.SUMMARY_ID],
        `${date.format("YYYY-MM-DD")} ${time.format("HH:mm:ss")}`
      );

      setIsLoading(false);
      props.setIsPushingToTeamRunOrAuditTrail(false);
      toast(res?.data?.message, "success");
    } catch (err) {
      setIsLoading(false);
      props.setIsPushingToTeamRunOrAuditTrail(false);
      console.log(err);
    }
    props.onClose(false);
  };

  function onCancel() {
    props.onClose(false);
    props.setIsPushingToTeamRunOrAuditTrail(false);
  }

  const theme = dark_Theme
    ? "other-date-picker-dark"
    : "other-date-picker-light";

  return (
    <Modal
      getContainer={"#content-root"}
      title={ACTION_BUTTONS_NAME.PUSH_OPTION_TO_AUDIT_TRAIL.label}
      width={"40vw"}
      onCancel={onCancel}
      open={props.isOpen}
      footer={[
        <Button
          style={{ backgroundColor: "gray" }}
          key={LABEL_NAME.CANCEL.label}
          onClick={onCancel}
        >
          {LABEL_NAME.CANCEL.label}
        </Button>,
        <Button
          key={LABEL_NAME.CONFIRM.label}
          loading={isLoading}
          onClick={onPushToAuditTrailClick}
          type="primary"
        >
          {LABEL_NAME.CONFIRM.label}
        </Button>,
      ]}
      className={`push-option__container ${
        dark_Theme ? "ant-modal-dark" : "ant-modal-light"
      }`}
    >
      <PushToAuditTrailItem label={LABEL_NAME.SHORTHAND.label}>
        <p>{props.data?.copy_shorthand}</p>
      </PushToAuditTrailItem>

      <PushToAuditTrailItem label={LABEL_NAME.DATE.label}>
        <DatePicker value={date} className={theme} onChange={onDateChange} />
      </PushToAuditTrailItem>

      <PushToAuditTrailItem className={"mt-2"} label={LABEL_NAME.TIME.label}>
        <TimePicker value={time} className={theme} onChange={onTimeChange} />
      </PushToAuditTrailItem>
    </Modal>
  );
};

export default PushToAuditTrail;
