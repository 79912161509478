import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { VolSurfaceContext } from "lib/contexts/VolSurfaceContext";
import { useIsFetching } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

import "core/styles/PricerAuditAgGridLayout.scss";
import { Col, Row } from "antd";
import { Spin } from "antd/lib";
import {
  batchUpdateGraphs,
  fetchUserVolSurface,
  getControlTableData,
  getGraphsData,
  setLiveForUserVolSurface,
  updateGraphsData,
  updateUserVolSurfaceTitle,
} from "api/VolSurface/VolSurface";
import { GlobalContext } from "lib/contexts/GlobalContext";
import FilterButton from "../Common/FilterButtons/FilterButtons";
import AgGridTable from "components/AuditTrail/AgGridTable/AgGridTable";
import {
  getDataFromLocalStorage,
  setDataToLocalStorage,
} from "lib/helpers/GeneralFuncHelpers";
import Highcharts from "highcharts";
import {
  SCROLL_POSITION,
  VOL_SURFACE_GRAPGH_FILTERS,
} from "lib/constant/VolSurface";
import useDebounce from "lib/hooks/useDebounce";
import { toast } from "lib/helpers/utilities";

import AgGridControlTable from "./AgGridControlTable/AgGridControlTable";
import { useVolSurfaceControlTable } from "lib/contexts/VolSurfaceControlTableContext";
import GraphsContainer from "./VolSurfaceGraph/GraphsContainer";
import VolSurfaceDataTable from "components/VolSurfaceData/VolSurfaceDataTable";

import "./style.scss";
import { onUpdateGraphTitleHelper } from "lib/helpers/VulSurface/VulSurface";
import StickyStrikeTable from "./StickyStrike/StickyStrikeTable";
import StickyStrikeContainer from "./StickyStrike/StickyStrikeContainer";
function VolSurfaceContainer() {
  const rowRef = useRef(null);
  const [start_date, setStart_date] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [currentTab, setCurrentTab] = useState("");
  const [isSticky, setIsSticky] = useState(false);
  const [end_date, setEnd_date] = useState("");
  const [changeGraphLine, setChangeGraphLine] = useState();
  const [graphIncreaseDescrease, setGraphIncreaseDescrease] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [comparisonGraph, setComparisonGraph] = useState(
    getDataFromLocalStorage(VOL_SURFACE_GRAPGH_FILTERS)?.comparison_surface_id
  );
  const [btnDisbale, setBtnDisbale] = useState({
    disabled: false,
    graph_id: 0,
  });

  const [periodTypeFilterLoading] = useState(false);
  const [biasIncreaseDecrease, setBiasIncreaseDecrease] = useState(0);
  const [batchUpdate, setBatchUpdate] = useState(false);
  const [liveGraphId, setLiveGraphId] = useState();
  const location = useLocation();
  const pathName = location.pathname;
  const [volSurfaceControl, setVolSurfaceControl] = useState();
  const [comparisonUpdated, setComparisonUpdated] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [volSurfaceButtonClicked, setVolSurfaceButtonClicked] = useState("");
  const [isRiskFreeRevert, setIsRiskFreeRevert] = useState(false);
  const isFetching = useIsFetching();
  const [initial_date, setInitial_date] = useState({
    start_date: "",
    end_date: "",
  });

  const debouncedGraphIncreaseDecrease = useDebounce(
    graphIncreaseDescrease,
    500
  );
  const debouncedBiasIncreaseDecrease = useDebounce(biasIncreaseDecrease, 500);
  const {
    globalState: { dark_Theme },
  } = useContext(GlobalContext);

  const {
    state: { volSurfaceData },
    setVolSurfaceData,
    setUserVolSurfaces,
    setVolSurfaceBtnLoading,
    setSelectedFiltersData,
  } = useContext(VolSurfaceContext);

  const {
    setVolSurfaceGraphData,
    scrollPosition,
    setVolSurfaceData: setRowsData,
    setVolCalcTable,
    volSurfaceTablesData: rowsData,
    tableHeight,
    risk_free,
    surfaceId,
  } = useVolSurfaceControlTable();

  const debounceSelectedFilters = useDebounce(selectedFilters, 500);
  const scrollableRef = useRef(null);
  const volSurfaceDataPath = pathName.includes("vol-surface-data");
  // useEffect that renders for the first time only when the  component load and get the selectedFilters from the local storage and set in the component state.
  useEffect(() => {
    let localFilterData = getDataFromLocalStorage(VOL_SURFACE_GRAPGH_FILTERS);
    const logLevel = localStorage.getItem("Reset_again");
    if (!logLevel) {
      localStorage.removeItem(VOL_SURFACE_GRAPGH_FILTERS);
      localStorage.removeItem("reset");
      localFilterData = {};
      localStorage.setItem("Reset_again", true);
    }
    const isFilterExist =
      localFilterData && Object.keys(localFilterData).length > 0;
    if (isFilterExist) {
      if (localFilterData.hasOwnProperty("start_date")) {
        setStart_date(localFilterData.start_date);
        setEnd_date(localFilterData.end_date);
        delete localFilterData.start_date;
        delete localFilterData.end_date;
      }
      setSelectedFilters(localFilterData);
    }
    setFirstRender(false);
  }, []);

  useEffect(() => {
    if (volSurfaceData) {
      let localFilterData = getDataFromLocalStorage(VOL_SURFACE_GRAPGH_FILTERS);
      if (
        !localFilterData.hasOwnProperty("start_date") &&
        volSurfaceData.default_end_date &&
        volSurfaceData.default_start_date
      ) {
        setStart_date(volSurfaceData.default_start_date);
        setEnd_date(volSurfaceData.default_end_date);
      }
    }
  }, [volSurfaceData]);

  // useEffect for storing the selected filters data in LocalStorage whenever the selectedFilters change
  useEffect(() => {
    if (!firstRender) {
      let localData = getDataFromLocalStorage(VOL_SURFACE_GRAPGH_FILTERS);
      let updatedFilters = {};
      if (localData) {
        updatedFilters = {
          ...localData,
        };
      }
      updatedFilters = { ...updatedFilters, start_date, end_date };
      setDataToLocalStorage(VOL_SURFACE_GRAPGH_FILTERS, updatedFilters);
    }
  }, [start_date, end_date]);

  // useEffect for storing the selected filters data in LocalStorage whenever the selectedFilters change
  useEffect(() => {
    if (!firstRender) {
      let localData = getDataFromLocalStorage(VOL_SURFACE_GRAPGH_FILTERS);
      let updatedFilters = {};
      if (localData) {
        updatedFilters = {
          ...localData,
        };
      }
      if (selectedFilters) {
        updatedFilters = { ...updatedFilters, ...selectedFilters };
      }
      setDataToLocalStorage(VOL_SURFACE_GRAPGH_FILTERS, updatedFilters);
    }
  }, [selectedFilters]);

  useEffect(() => {
    setSelectedFiltersData(selectedFilters);
  }, [selectedFilters, setSelectedFiltersData]);

  // UseEffect will run whenever the user will change the "Set Live Surface" dropdown value...
  useEffect(() => {
    if (comparisonUpdated && liveGraphId) {
      let bodyParams = {
        vol_surface_id: liveGraphId,
      };
      setLiveForUserVolSurface(bodyParams);
    }
  }, [liveGraphId, comparisonUpdated]);

  useEffect(() => {
    const oldScrollPosition = getDataFromLocalStorage(SCROLL_POSITION);
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = oldScrollPosition || scrollPosition;
    }
  }, [rowsData, scrollPosition]);

  // useEffect (for Grapgh) when user select the compoarison value from the "Set Comparison Surface" it will call the API getGraphsData and will render the updated graph.
  useEffect(() => {
    if (volSurfaceDataPath) return;
    const params = {
      product_contract_style_id: selectedFilters["product_id"],
      comparison_surface_id: selectedFilters?.comparison_surface_id,
      risk_free: selectedFilters?.risk_free || 0,
    };
    let filters = { ...selectedFilters };
    delete filters.risk_free;
    if (selectedFilters["product_id"]) {
      setComparisonUpdated(false);
      setVolSurfaceBtnLoading(true);
      getGraphsData(params)
        .then((res) => {
          const response = res.data.data;
          setVolSurfaceBtnLoading(false);
          setVolSurfaceData(response);
        })
        .catch((e) => {
          setVolSurfaceBtnLoading(false);
        });
    }
  }, [
    comparisonGraph,
    liveGraphId,
    selectedFilters,
    setVolSurfaceBtnLoading,
    setVolSurfaceData,
  ]);

  useEffect(() => {
    if (volSurfaceDataPath) return;
    if (selectedFilters["product_id"]) {
      fetchUserVolSurface({
        product_contract_style_id: selectedFilters["product_id"],
      }).then((res) => {
        setUserVolSurfaces(res.data.data);
      });
    }
  }, [selectedFilters["product_id"]]);

  // This will effect only when user update a single graph only by pressing the up and down button on the above of that graph.
  useEffect(() => {
    if (volSurfaceDataPath) return;
    if ((changeGraphLine, debouncedGraphIncreaseDecrease)) {
      const getVolSurafceLocalFilters = getDataFromLocalStorage(
        VOL_SURFACE_GRAPGH_FILTERS
      );
      let prepareBody = {
        is_increase: debouncedGraphIncreaseDecrease > 0 ? 1 : 0,
        is_whole_graph: 1,
        user_vol_smile_id: changeGraphLine.user_vol_smile_id,
        graph_id: changeGraphLine.graph_id,
        y_increase: Math.abs(debouncedGraphIncreaseDecrease),
        comparison_surface_id: selectedFilters?.comparison_surface_id,
        x_axis: getVolSurafceLocalFilters.x_axis,
        risk_free:
          selectedFilters.x_axis === "delta" ? selectedFilters.risk_free : null,
      };
      setBtnDisbale({ disabled: true, graph_id: changeGraphLine.graph_id });
      updateGraphsData(prepareBody)
        .then((res) => {
          setVolSurfaceGraphData(res.data.data);
          setBtnDisbale({
            disabled: false,
            graph_id: changeGraphLine.graph_id,
          });
        })
        .catch((error) => {
          console.log("Error >>", error);
          setBtnDisbale({ disabled: false, graph_id: 0 });
        });
      setBtnDisbale({ disabled: false, graph_id: 0 });

      setGraphIncreaseDescrease(0);
      setChangeGraphLine(undefined);
    }
  }, [debouncedGraphIncreaseDecrease, selectedFilters]);

  // useEffect will run the effect when user click on "Whole Surface" (Biase) (Up and Down) buttons, it will update all the graphs
  useEffect(() => {
    if (volSurfaceDataPath) return;
    if (
      debouncedBiasIncreaseDecrease &&
      volSurfaceData &&
      Object.keys(volSurfaceData).length > 0
    ) {
      let reqBody = {
        is_increase: debouncedBiasIncreaseDecrease > 0 ? 1 : 0,
        y_increase: Math.abs(debouncedBiasIncreaseDecrease),
        product_contract_style_id: selectedFilters?.product_id,
        risk_free: selectedFilters?.risk_free,
      };
      setBatchUpdate(true);
      batchUpdateGraphs(reqBody)
        .then((res) => {
          if (res.status === 200) {
            const params = {
              startDate: start_date,
              endDate: end_date,
              risk_free: selectedFilters?.risk_free || 0,
              product_contract_style_id: selectedFilters?.product_id,
              comparison_surface_id: selectedFilters?.comparison_surface_id,
              user_vol_surface_id: liveGraphId,
              comparison_user_vol_surface_id: comparisonGraph,
              ...debounceSelectedFilters,
            };
            setBiasIncreaseDecrease(0);
            let filters = { ...selectedFilters };
            delete filters.risk_free;
            toast(res?.data?.message, "success");
            setVolSurfaceBtnLoading(true);
            fetchControlTable();
            delete params?.product_id;
            fetchGraphsData(params);
          } else {
            toast(res?.data?.message, "info");
          }
        })
        .catch((error) => {
          console.log("Error while batch updating");
          setBatchUpdate(false);
          setVolSurfaceBtnLoading(false);
        });
    } else {
      setBiasIncreaseDecrease(0);
    }
  }, [debouncedBiasIncreaseDecrease, selectedFilters, end_date, start_date]);

  /**
   * Function to update the graph title and perform related operations.
   */
  const onUpdateGraphTitle = () => {
    if (volSurfaceDataPath) return;
    const { reqBody, querParams } = onUpdateGraphTitleHelper(
      volSurfaceControl,
      volSurfaceData,
      selectedFilters
    );

    if (volSurfaceData && Object.keys(volSurfaceData).length > 0) {
      setVolSurfaceControl("");
      updateUserVolSurfaceTitle(reqBody).then((res) => {
        toast(res?.data?.message, "success");
        fetchUserVolSurface(querParams).then((res) => {
          setUserVolSurfaces(res.data.data);
        });
      });
    }
  };

  const onBiasChange = (is_increase) => {
    if (is_increase) {
      setBiasIncreaseDecrease((prev) => prev + 1);
    } else {
      setBiasIncreaseDecrease((prev) => prev - 1);
    }
  };

  useEffect(() => {
    if (!rowRef.current) return;
    const handleScroll = (e) => {
      if (rowRef.current.scrollTop > 570) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    const myDiv = document.querySelector(".my-vol-surface");
    myDiv.addEventListener("scroll", handleScroll);
    return () => {
      myDiv.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const selectedFiltersParams = useMemo(() => {
    return {
      product_contract_style_id: selectedFilters?.product_id,
      risk_free: selectedFilters?.risk_free || 0,
    };
  }, [selectedFilters?.product_id, selectedFilters?.risk_free]);

  function fetchGraphsData(params) {
    getGraphsData(params)
      .then((res) => {
        if (res.status === 200) {
          const response = res.data.data;
          setVolSurfaceData(response);
          setIsRiskFreeRevert(false);
        } else {
          setIsRiskFreeRevert(true);
        }

        setVolSurfaceBtnLoading(false);
        setBatchUpdate(false);
      })
      .catch((err) => {
        setVolSurfaceBtnLoading(false);
        setBatchUpdate(false);
        console.log("Error while getting updated graphs with filters>>", err);
      });
  }

  function fetchControlTable() {
    getControlTableData({
      product_contract_style_id: selectedFilters?.product_id,
      risk_free: selectedFilters?.risk_free || 0,
      comparison_surface_id: selectedFilters?.comparison_surface_id,
    }).then((res) => {
      setRowsData(res.data.data.main_control_table);
      setVolCalcTable(res.data.data.vol_calc_table);
    });
  }

  return (
    <Row
      ref={rowRef}
      className={`my-vol-surface ${isSticky ? "sticky" : ""}`}
      justify={"start"}
    >
      <Col span={24}>
        <Col
          style={{
            padding: 20,
            height:
              volSurfaceButtonClicked !== "Table" &&
              !volSurfaceDataPath &&
              currentTab === "Sticky Delta" &&
              "540px",
          }}
        >
          <FilterButton
            setSelectedTab={setVolSurfaceButtonClicked}
            selectedTab={volSurfaceButtonClicked}
            start_date={start_date}
            end_date={end_date}
            initial_date={initial_date}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            onBiasChange={onBiasChange}
            batchUpdate={batchUpdate}
            onUpdateGraphTitle={onUpdateGraphTitle}
            setLiveGraphId={setLiveGraphId}
            liveGraphId={liveGraphId}
            setVolSurfaceControl={setVolSurfaceControl}
            volSurfaceControl={volSurfaceControl}
            periodTypeFilterLoading={periodTypeFilterLoading}
            setComparisonGraph={setComparisonGraph}
            setComparisonUpdated={setComparisonUpdated}
            comparisonGraph={comparisonGraph}
            isRiskFreeRevert={isRiskFreeRevert}
            setIsRiskFreeRevert={setIsRiskFreeRevert}
            initialRiskFree={risk_free}
            surface_id={surfaceId}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            setEnd_date={setEnd_date}
            setStart_date={setStart_date}
            setVolSurfaceBtnLoading={setVolSurfaceBtnLoading}
            setVolSurfaceData={setVolSurfaceData}
            dateTime={dateTime}
            setDateTime={setDateTime}
          />
        </Col>

        {currentTab === "Sticky Delta" ? (
          <>
            {volSurfaceButtonClicked === "Table" && !volSurfaceDataPath ? (
              <Col
                span={24}
                style={{ margin: "auto, -20px" }}
                className={`${
                  dark_Theme || false
                    ? "pricer-ag-grid__container_dark"
                    : "pricer-ag-grid__container"
                }`}
              >
                <AgGridTable
                  volSurfaceButtonClicked={volSurfaceButtonClicked}
                  start_date={start_date}
                  selectedFilters={selectedFiltersParams}
                  end_date={end_date}
                  setStart_date={setStart_date}
                  setEnd_date={setEnd_date}
                  setInitial_date={setInitial_date}
                />
              </Col>
            ) : !selectedFilters["product_id"] ? null : (
              <Col span={24} style={{ padding: "20px", height: "fit-content" }}>
                {isFetching ? (
                  <div className="vol-surface-spin">
                    <Spin size="large" />
                  </div>
                ) : (
                  <>
                    {!volSurfaceDataPath ? (
                      <Row gutter={[24, 40]} justify={"center"}>
                        <AgGridControlTable scrollableRef={scrollableRef} />
                        {selectedFilters["product_id"] && !isFetching && (
                          <GraphsContainer
                            scrollRef={scrollableRef}
                            volSurfaceData={volSurfaceData}
                            rowsData={rowsData}
                            setBtnDisbale={setBtnDisbale}
                            btnDisbale={btnDisbale}
                            tableHeight={tableHeight}
                            Highcharts={Highcharts}
                            setVolSurfaceGraphData={setVolSurfaceGraphData}
                          />
                        )}
                      </Row>
                    ) : (
                      <VolSurfaceDataTable />
                    )}
                  </>
                )}
              </Col>
            )}
          </>
        ) : (
          <StickyStrikeContainer currentTab={currentTab} />
        )}
      </Col>
    </Row>
  );
}

export default VolSurfaceContainer;
