import StickyStrikeGraphs from "./StickyStrikeGraphs";
import StickyStrikeTable from "./StickyStrikeTable";

function StickyStrikeContainer({ currentTab }) {
  if (currentTab === "Sticky Delta") return;
  return (
    <div className="gap-2 d-flex p-2">
      <StickyStrikeTable />
      <StickyStrikeGraphs />
    </div>
  );
}

export default StickyStrikeContainer;
